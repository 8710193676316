.header {
  height: 30px;
  background-color: #f9f9f9;
  padding: 12px 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.logo {
  max-height: 35px;
  user-select: none;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

@media (min-width: 1224px) {
  .navigation {
    width: 1200px;
    margin: 0 auto;
  }
}

.sidebarButton {
  font-size: 24px;
  color: #333333;
  width: 50px;
  text-align: center;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 50px;
  height: 100%;
  width: 100%;
}