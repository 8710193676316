.view {
  height: 100%;
  padding: 20px;
}

.title {
  font-size: 26px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
}

.list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  background-color: #f9f9f9;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  margin: 3px;
  width: 80vw;
  font-size: 12px;
  max-width: 400px;
  justify-content: space-between;
  border-radius: 6px;
}

.listItem:nth-child(n + 11) > * {
  background-color: #9e9e9e;
}

.line {
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 2px solid white;
  width: 80vw;
  max-width: 500px;
}

.rowLeft {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.flag {
  width: 24px;
  height: auto;
  margin-right: 12px;
  border: 1px solid black;
}

.name {
  font-weight: 600;
}

.artist {
  font-size: 8px;
  line-height: 8px;
  margin-left: 1px;
}

.saveButton {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: white;
  border: none;
}
