.view {
  height: 100%;
  padding: 20px;
}

.title {
  font-size: 26px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
}

.list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  background-color: #54859c87;
  margin: 4px;
  width: 80vw;
  max-width: 400px;
  border-radius: 3px;
  overflow: hidden;
}

.hidden {
  opacity: 0;
}

.revealed {
  transition-duration: 2s;
}

.row {
  background-color: #f9f9f9;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-size: 12px;
  justify-content: space-between;
  cursor: pointer;
}

.rowLeft {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.flag {
  width: 24px;
  height: auto;
  margin-right: 12px;
  border: 1px solid black;
}

.name {
  font-weight: 600;
}

.artist {
  font-size: 8px;
  line-height: 8px;
  margin-left: 1px;
}

.score {
  font-weight: 800;
  font-size: 16px;
}
