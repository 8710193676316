.view {
  max-width: 80vh;
  width: 400px;
  display: flex;
  margin: auto;
}

.policy {
  margin-top: 20vh;
  text-align: justify;
}
