@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

html {
  min-height: 100%;
  background-color: #030b2a;
  background: linear-gradient(
    180deg,
    rgb(1, 7, 43) 0%,
    rgba(3, 11, 42, 1) 5%,
    rgb(2, 48, 105) 100%
  );
  background-size: cover;
  color: #f6f6f6;
  font-weight: 300;
}
body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
}

textarea,
input {
  outline: none;
  border: none;
  color: gray;
}
