.sidebar {
  position: fixed;
  top: 54px;
  bottom: 0;
  left: -250px;
  width: 200px;
  background-color: #f9f9f9;
  transition: 0.3s;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.open {
  left: 0;
}

.row {
  color: gray;
  height: 40px;
  padding: 0 22px;
  line-height: 40px;
  user-select: none;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.row:hover {
  background: #d6d6d6;
  cursor: pointer;
}

.rowText {
  margin-left: 15px;
}

.bottom {
  margin-bottom: 8px;
}

.spotifyLogo {
  margin: 20px auto;
  height: 40px;
}
