.view {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.join {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  width: 600px;
  max-width: 100%;
  height: 150px;
  margin-bottom: 12px;
  border-radius: 6px;
}

.text {
  font-weight: 300;
  color: #505050;
}

.form {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  height: 32px;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  box-shadow: 0px 2px 4px #00000036;
  border-radius: 3px;
  overflow: hidden;
}

.input {
  padding: 6px 16px;
  font-size: 12px;
  line-height: 12px;
}

.input::placeholder {
  color: lightgray;
}

.create {
  margin-top: 60px;
}

.button {
  color: #00000096;
  width: 66px;
  font-weight: 400;
  background: lightgreen;
  cursor: pointer;
}
