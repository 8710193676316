@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
html {
  min-height: 100%;
  background-color: #030b2a;
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(1, 7, 43)),
    color-stop(5%, rgba(3, 11, 42, 1)),
    to(rgb(2, 48, 105))
  );
  background: linear-gradient(
    180deg,
    rgb(1, 7, 43) 0%,
    rgba(3, 11, 42, 1) 5%,
    rgb(2, 48, 105) 100%
  );
  background-size: cover;
  color: #f6f6f6;
  font-weight: 300;
}
body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
}

textarea,
input {
  outline: none;
  border: none;
  color: gray;
}

.App {
  text-align: center;
  height: 100%;
}

.route {
  padding-top: 54px;
}

@media (min-width: 1224px) {
  .route {
    width: 1200px;
    margin: 0 auto;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Voting_view__130Y4 {
  height: 100%;
  padding: 20px;
}

.Voting_title__3wgL0 {
  font-size: 26px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
}

.Voting_list__WVApl {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Voting_row__1fVkT {
  background-color: #f9f9f9;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  margin: 3px;
  width: 80vw;
  font-size: 12px;
  max-width: 400px;
  justify-content: space-between;
  border-radius: 6px;
}

.Voting_listItem__3y3u5:nth-child(n + 11) > * {
  background-color: #9e9e9e;
}

.Voting_line__ow6Bx {
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 2px solid white;
  width: 80vw;
  max-width: 500px;
}

.Voting_rowLeft__UMOz6 {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.Voting_flag__343VQ {
  width: 24px;
  height: auto;
  margin-right: 12px;
  border: 1px solid black;
}

.Voting_name__394qO {
  font-weight: 600;
}

.Voting_artist__3W9Qq {
  font-size: 8px;
  line-height: 8px;
  margin-left: 1px;
}

.Voting_saveButton__liByq {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: white;
  border: none;
}

.Header_header__1MOL- {
  height: 30px;
  background-color: #f9f9f9;
  padding: 12px 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.Header_logo__3EvUy {
  max-height: 35px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Header_navigation__1m80W {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

@media (min-width: 1224px) {
  .Header_navigation__1m80W {
    width: 1200px;
    margin: 0 auto;
  }
}

.Header_sidebarButton__3o0hZ {
  font-size: 24px;
  color: #333333;
  width: 50px;
  text-align: center;
  cursor: pointer;
}

.Header_overlay__28knl {
  position: fixed;
  top: 50px;
  height: 100%;
  width: 100%;
}
.Sidebar_sidebar__2n2bb {
  position: fixed;
  top: 54px;
  bottom: 0;
  left: -250px;
  width: 200px;
  background-color: #f9f9f9;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Sidebar_open__2otTo {
  left: 0;
}

.Sidebar_row__10FMH {
  color: gray;
  height: 40px;
  padding: 0 22px;
  line-height: 40px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.Sidebar_row__10FMH:hover {
  background: #d6d6d6;
  cursor: pointer;
}

.Sidebar_rowText__jC0tS {
  margin-left: 15px;
}

.Sidebar_bottom__32e2N {
  margin-bottom: 8px;
}

.Sidebar_spotifyLogo__3U48D {
  margin: 20px auto;
  height: 40px;
}

.Login_view__2AlQx {
  height: 100%;
}

.Login_wrapper__1Syn3 {
  margin: auto;
  padding-top: 100px;
  text-align: center;
  font-size: 32px;
}

.Login_firebaseUIWrapper__rikXZ {
  margin-top: 100px;
}
.Group_view__1ARnj {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Group_join__qiKyC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  width: 600px;
  max-width: 100%;
  height: 150px;
  margin-bottom: 12px;
  border-radius: 6px;
}

.Group_text__12KPq {
  font-weight: 300;
  color: #505050;
}

.Group_form__a7ic_ {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  height: 32px;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  box-shadow: 0px 2px 4px #00000036;
  border-radius: 3px;
  overflow: hidden;
}

.Group_input__v7ahH {
  padding: 6px 16px;
  font-size: 12px;
  line-height: 12px;
}

.Group_input__v7ahH::-webkit-input-placeholder {
  color: lightgray;
}

.Group_input__v7ahH:-ms-input-placeholder {
  color: lightgray;
}

.Group_input__v7ahH::-ms-input-placeholder {
  color: lightgray;
}

.Group_input__v7ahH::placeholder {
  color: lightgray;
}

.Group_create__2GpLc {
  margin-top: 60px;
}

.Group_button__1qnua {
  color: #00000096;
  width: 66px;
  font-weight: 400;
  background: lightgreen;
  cursor: pointer;
}

.Leaderboard_view__2ccFP {
  height: 100%;
  padding: 20px;
}

.Leaderboard_title__1-C7X {
  font-size: 26px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
}

.Leaderboard_list__FxHuc {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Leaderboard_item__1kXzm {
  background-color: #54859c87;
  margin: 4px;
  width: 80vw;
  max-width: 400px;
  border-radius: 3px;
  overflow: hidden;
}

.Leaderboard_hidden__2FaCW {
  opacity: 0;
}

.Leaderboard_revealed__13Ya2 {
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
}

.Leaderboard_row__6y4TU {
  background-color: #f9f9f9;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-size: 12px;
  justify-content: space-between;
  cursor: pointer;
}

.Leaderboard_rowLeft__tTN_T {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.Leaderboard_flag__53y_4 {
  width: 24px;
  height: auto;
  margin-right: 12px;
  border: 1px solid black;
}

.Leaderboard_name__35dh4 {
  font-weight: 600;
}

.Leaderboard_artist__35WZD {
  font-size: 8px;
  line-height: 8px;
  margin-left: 1px;
}

.Leaderboard_score__JNB0W {
  font-weight: 800;
  font-size: 16px;
}

.PrivacyPolicy_view__2I-Vu {
  max-width: 80vh;
  width: 400px;
  display: flex;
  margin: auto;
}

.PrivacyPolicy_policy__1tzMl {
  margin-top: 20vh;
  text-align: justify;
}

