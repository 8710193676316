.view {
  height: 100%;
}

.wrapper {
  margin: auto;
  padding-top: 100px;
  text-align: center;
  font-size: 32px;
}

.firebaseUIWrapper {
  margin-top: 100px;
}